import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI, Link, EntryProps } from 'contentful-management';

/** Update tags on an entry */
export const updateEntryTags = async (cma: PlainClientAPI, sdk: FieldExtensionSDK, tags: Array<Link<"Tag">>): Promise<EntryProps> => {
  const sys = sdk.entry.getSys();
  return await cma.entry.patch(
    { entryId: sys.id },
    [{ path: '/metadata/tags', op: 'replace', value: tags }],
    { 'X-Contentful-Version': sys.version }
  );
};