import React from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import { Autocomplete, Text, Stack, Card, Button } from '@contentful/f36-components';

const baseWindowHeight = 215;

const endpoint = 'https://platform.bombas.com/graphql';
// const endpoint = 'http://localhost:3000/dev/graphql';

interface VariantSkuFieldFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

export interface Item {
  [index: string]: unknown;
  sku: string;

  Sku: {
    [index: string]: unknown;
    id: string;
    cnl: string;
    active: boolean;
    sku: string;

    Colorway: {
      [index: string]: unknown;
      id: string;
      cnl: string;
      ColorSpecification: {
        [index: string]: unknown;
        id: string;
        cnl: string;
        active: boolean;
      }
    }
    ProductSize: {
      [index: string]: unknown;
      id: string;
      cnl: string;
    }
    Season: {
      [index: string]: unknown;
      id: string;
      cnl: string;
      active: boolean;
    }
    Style: {
      [index: string]: unknown;
      id: string;
      cnl: string;
      active: boolean;
      name: string;
      bee_parent_item: string;
      composition: string;
      care_instructions: string;
      StyleTag: {
        [index: string]: unknown;
        id: string;
        cnl: string;
        category: string;
        class: string;
        collection: string;
      }
    }
  }
}

export enum Loading {
  None = -1,
  Initialized = 0,
  Success = 1,
  Error = 2,
}

const VariantSkuField = ({ sdk }: VariantSkuFieldFieldProps) => {
  const [loadingState, setLoadingState] = React.useState<Loading>(Loading.None);
  const [inputValue, setInputValue] = React.useState('');
  const [items, setItems] = React.useState<Array<Item>>([]);
  const showButton = items.length < 1 && inputValue !== '';

  React.useEffect(() => {
    if (inputValue !== '') {
      setLoadingState(Loading.Initialized);

      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `query itemSearchRaw($query: String!) { itemSearchRaw(query: $query) }`,
          variables: { query: inputValue },
        }),
      }).then(res => res.json()).then(res => {
        const foundItems = res?.data?.itemSearchRaw || [];
        setItems(foundItems);
        setLoadingState(Loading.Success);
      }).catch(err => {
        console.error(err);
        setLoadingState(Loading.Error)
      });
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (inputValue === '') {
      sdk.window.updateHeight(baseWindowHeight - 25);
    } else {
      if ([Loading.Initialized, Loading.Success].includes(loadingState)) {
        sdk.window.updateHeight(baseWindowHeight + (showButton ? 25 : 0) + (items.length * 35));
      } else {
        sdk.window.updateHeight(baseWindowHeight + (showButton ? 75 : 0));
      }
    }
  }, [sdk, loadingState, inputValue, items, showButton]);

  // fetching data on each input value change
  // NOTE: Consider using throttle/debounce here for better performance
  const handleInputValueChange = (value: string | Item) => {
    if (typeof value === 'string') {
      setInputValue(value);
   }
  };

  const handleSelectItem = (item: Item) => {
    sdk.field.setValue(item.sku)
  };

  return (
    <>
      <Card marginBottom="spacingXs" marginTop="spacingS">
        <Stack flexDirection="column" alignItems="start" spacing="spacingS">
          {loadingState === Loading.Error && <Text fontColor="red600">ERROR LOADING RESULTS<br/></Text>}
          <Text>Current Value: {sdk.field.getValue()}</Text>
          <Autocomplete
            onInputValueChange={handleInputValueChange}
            onSelectItem={handleSelectItem}
            isLoading={loadingState === Loading.Initialized}
            defaultValue={inputValue}
            clearAfterSelect={true}
            noMatchesMessage="No items found, try being more specific or force submit the SKU."
            items={items}
            renderItem={(item: Item) => (
              <span>
                {item?.sku}({item?.Sku?.Style?.name} ({item?.Sku?.Style?.StyleTag?.node_name}) - {item?.Sku?.Colorway?.ColorSpecification?.site_name} {item?.Sku?.ProductSize?.node_name})
              </span>
            )}
          />
          <Text fontColor="gray500" fontSize="fontSizeS">
            Search by SKU, style number, and more! If your search is not showing the results you expect,
            but you are sure of the SKU that you are inputting, you can use the submit button in the bottom
            right corner of this SKU widget.
          </Text>
        </Stack>
      </Card>
      {showButton && (
        <div style={{ width: "100%", textAlign: 'right' }}>
          <Button onClick={() => {
            handleSelectItem({ sku: inputValue } as unknown as Item);
            setInputValue('');
          }}>Submit SKU</Button>
        </div>
      )}
    </>
  );
};

export default VariantSkuField;
