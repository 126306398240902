import React, { useEffect } from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { SysLink, EntryProps, PlainClientAPI } from 'contentful-management';
import { findAsync } from '../../utilities';
import { useFieldChangeHook } from '../Field.hooks';

interface PageSectionsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const DEMO_PRODUCT_FEATURE_IDS = [
  '4IxQjW5GKT4wxKnx87pJMa',
  '35OpMwg40JLgWLwnS4cvpq',
  '2bacelmi5aYLfLijsdmbZO'
];

const PageSectionsField = ({ sdk, cma }: PageSectionsFieldProps) => {
  useEffect(() => {
    sdk.window.startAutoResizer();
  });

  useFieldChangeHook(sdk, async (value) => {
    const newItems: SysLink[] = [];
    const product = await findAsync(
      value,
      async (item) =>
        await cma.entry.get({ entryId: item.sys.id }).then((e) => {
          if (e.sys.contentType.sys.id === 'product') return true;
          return false;
        })
    );

    if (product) {
      // Create CardContainer with first 3 product features
      // TODO: Get first 3 features from first variant via Bombas graph.
      const hasFeaturedTechModule = await findAsync(
        value,
        async (item) =>
          await cma.entry
            .get({ entryId: item.sys.id })
            .then((e) =>
              e.metadata?.tags.some(
                (tag) => tag.sys.id === 'productFeaturedTech'
              )
            )
      );

      // NOTE: Should this check be more strict?
      // IE. Should we also check that a module with the same name doesn't already exist?
      if (!hasFeaturedTechModule) {
        const productTitle: string = await cma.entry
          .get({ entryId: sdk.entry.getSys().id })
          .then((e) => e.fields.title[sdk.locales.default]);
        const entry = await cma.entry
          .create(
            {
              contentTypeId: 'cardContainer'
            },
            {
              metadata: {
                tags: [
                  {
                    sys: {
                      type: 'Link',
                      linkType: 'Tag',
                      id: 'productFeaturedTech'
                    }
                  }
                ]
              },
              fields: {
                title: {
                  [sdk.locales.default]: `${productTitle} Featured Tech`
                },
                cards: {
                  [sdk.locales.default]: DEMO_PRODUCT_FEATURE_IDS.map(
                    (featureId) => ({
                      sys: {
                        type: 'Link',
                        linkType: 'Entry',
                        id: featureId
                      }
                    })
                  )
                }
              }
            }
          )
          .then((e) => cma.entry.publish({ entryId: e.sys.id }, e));
        newItems.push({
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: entry.sys.id
          }
        });
      }

      // TEMP: This demonstrates fetching a donation module but will need to be refactored to dynamically
      //   choose a donation module based on a Products category + department.
      const donationModules: EntryProps[] | [] = await cma.entry
        .getMany({ query: { 'metadata.tags.sys.id[all]': 'donation' } })
        .then((r) => r.items || []);
      const validModuleIds = new RegExp(
        donationModules.map((mod) => mod.sys.id).join('|')
      );
      const hasAnyDonationModule = value?.some((item) =>
        validModuleIds.test(item.sys.id)
      );

      if (!hasAnyDonationModule) {
        const relevantModule = donationModules.find((mod) =>
          mod.fields.title[sdk.locales.default].includes('Socks')
        );
        if (relevantModule) {
          newItems.push({
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: relevantModule?.sys.id
            }
          });
        }
      }
    }

    if (newItems.length) {
      sdk.field.setValue([...(value || []), ...newItems]);
    }
  });

  return (
    <MultipleEntryReferenceEditor
      viewType="link"
      sdk={sdk}
      hasCardEditActions
      isInitiallyDisabled={false}
      parameters={{
        instance: { showCreateEntityAction: true, showLinkEntityAction: true }
      }}
    />
  );
};

export default PageSectionsField;
